<template>
  <section>
    <HeaderCustom isHome='true'></HeaderCustom>
    <div class="container">
      <div :class="$style.header">
        <div class="row" :class="$style.columnsCustom">
          <!-- <div class="col-md-3 col-sm-12 mt-1">
            <Profile></Profile>
          </div> -->
          <div class="col-md-12 col-sm-12 mt-1">
            <Chart v-if="isTeacher"></Chart>
            <Homeworks v-if="isStudent" :lmsLoading='this.ready' :googleLoading='$store.state.googleReady'></Homeworks>
          </div>
        </div>
      </div>
      <!-- <hr style="background: #323964;"> -->
      <br>
      <!-- <Platforms></Platforms> -->
      <!-- <h1 class="text-center mt-5">Platformlar Buraya</h1> -->
      <!-- <hr style="background: #323964;"> -->
      <br>
      <!-- <Resources v-if="isStudent" :loading='this.ready'></Resources> -->
      <Collections :isHome="true"></Collections>
      <RsgLink></RsgLink>
    </div>
    <div class="mt-3"></div>
  </section>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import HeaderCustom from '@/components/HeaderCustom';
  import Homeworks from '@/components/Homeworks';
  import Chart from '@/components/Chart';
  import Collections from '@/components/Collections';
  import RsgLink from '@/components/RSGLink';
  // import Platforms from '@/components/Platforms';

  export default {
    name: 'home',
    data() {
      return {
        ready: false,
        main: null,
      };
    },
    components: {
      HeaderCustom,
      Homeworks,
      Chart,
      Collections,
      RsgLink,
      // Platforms,
    },
    computed: {
      ...mapState(['userDetails', 'username', 'password']),
      isStudent() {
        // this.main = this.userDetails.organization.find(x => x.type === 'main').id;
        const role = this.$store.state.roleType;
        if (role === 'student') {
          return true;
        }
        return false;
      },
      isTeacher() {
        const role = this.$store.state.roleType;
        if (role === 'teacher') {
          return true;
        }
        return false;
      },
    },
    methods: {
      ...mapActions(['getPackages', 'getLmsAssignments', 'getLmsIndAssignments']),
    },
    async beforeMount() {
      if (this.$store.state.freeContents.length > 0
      && this.$store.state.assignments.length > 0) { //eslint-disable-line
        //* Daha önceden LMS datası çekildiyse datanın gelmesi beklenmeden true dönülür
        this.ready = true;
        this.getPackages();
        this.getLmsAssignments();
        this.getLmsIndAssignments();
      } else {
        await this.getLmsAssignments();
        await this.getLmsIndAssignments();
        await this.getPackages();
        this.ready = true;
      }
    },
  };
</script>

<style src='./style.less' lang='less' module/>