<template>
    <div style="text-align:center; margin-top:10px;">
    <a target="_blank" href="https://rsg-enkhuizen.nl/international">
      <img src="/static/img/rsg-enk.jpg" alt="" width="150">
    </a>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>