<template>
  <div>
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">

        <div class="col-md-4 offset-md-4">
          <Search :searchList='this.data' @search='search' ref="txtSearch"></Search>

        </div>
        <div class="col-md-12">
          <h1 class="title menuTitle" style="font-weight: normal;" :class='$style.menuTitle'>{{title}}</h1>
        </div>
      </div>
      <div>
        <ul class="nav nav-tabs row">
          <li class="nav-item col">
            <a class="nav-link active" :class='$style.customNavLink' @click="updateView('current')" href="#" ref="current">Current Assignments</a>
          </li>
          <!-- <li class="nav-item col">
            <a class="nav-link" :class='$style.customNavLink' @click="updateView('google')" href="#" ref="google">Google Classroom</a>
          </li> -->
          <li class="nav-item col">
            <a class="nav-link" @click="updateView('past')" :class='$style.customNavLink' href="#" ref="past">Past Assignments</a>
          </li>
        </ul>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <div v-else>
        <!-- <div v-if="activeTab === 'google' && activeDataSet.length > 0" class="card-columns" :class="$style.columns">
          <div class="card" :class="[$style.column, $style.customCard]" v-for="(element,i) in activeDataSet" :key="i">
            <div :class="$style.divMaterial">
              <a :href="element.alternateLink" target="_blank" :class="$style.customLink">
                <p :class="$style.contentTitle">{{element.title}}</p>
                <p v-if="element.description" :class="$style.contentDesc">{{element.description}}</p>
                <div v-if="element.materials" v-for="(item, i) in element.materials" :key="i">
                  <div v-if="item.form">
                    <a :href="item.form.formUrl" target="_blank" :class="$style.customLink">
                      <img :src="item.form.thumbnailUrl" :class="$style.coverImg" onerror="this.src='https://d30y9cdsu7xlg0.cloudfront.net/png/67120-200.png'">
                      <p :class="$style.contentSubTitle">{{item.form.title}}</p>
                    </a>
                  </div>
                  <div v-if="item.link">
                    <a :href="item.link.url" target="_blank" :class="$style.customLink">
                      <img :src="item.link.thumbnailUrl" :class="$style.coverImg" onerror="this.src='https://d30y9cdsu7xlg0.cloudfront.net/png/67120-200.png'">
                      <p :class="$style.contentSubTitle">{{item.link.title}}</p>
                    </a>
                  </div>
                  <div v-if="item.driveFile">
                    <a :href="item.driveFile.driveFile.alternateLink" target="_blank" :class="$style.customLink">
                      <img :src="item.driveFile.driveFile.thumbnailUrl" :class="$style.coverImg" onerror="this.src='https://d30y9cdsu7xlg0.cloudfront.net/png/67120-200.png'">
                      <p :class="$style.contentSubTitle">{{item.driveFile.driveFile.title}}</p>
                    </a>
                  </div>
                  <div v-if="item.youtubeVideo">
                    <a :href="item.youtubeVideo.alternateLink" target="_blank" :class="$style.customLink">
                      <img :src="item.youtubeVideo.thumbnailUrl" :class="$style.coverImg">
                      <p :class="$style.contentSubTitle">{{item.youtubeVideo.title}}</p>
                    </a>
                  </div>
                </div>
                <hr v-if="element.dueDate">
                <div v-if="element.dueDate" :class="$style.divDueDate">
                  <span class="badge badge-info" :class="$style.customBadge">Due Date</span>
                  <span v-if="element.dueTime" class="badge" :class="[$style.txtDueDate, $style.customBadge]">{{element.dueDate.day}}.{{element.dueDate.month}}.{{element.dueDate.year}} {{element.dueTime.hours + 3}}:{{element.dueTime.minutes
                    || '00'}}</span>
                  <span v-else class="badge" :class="[$style.txtDueDate, $style.customBadge]">{{element.dueDate.day}}.{{element.dueDate.month}}.{{element.dueDate.year}}</span>
                </div>
              </a>
            </div>
          </div>
        </div> -->
        <div v-if='activeDataSet.length > 0' class="row" :class="$style.columns">
          <div class="col-md-3" :class="$style.column" v-for="(item,i) in activeDataSet" :key="i">
            <Card :contentType='contentType' :scormCode='item.scorm.scorm_code' :scormTitle='item.scorm.scorm_name' :id='item.scorm.scorm_id' :title='item.assignment_name' :imgpath='item.scorm.scorm_image'
              :score='item.scorm.scorm_scores.total_score' :date='item.end_date' :stateName='item.state_name' :scormDesc='item.scorm.scorm_desc' :packageId='item.assignment_id' :collPath='item.scorm.collection_path'></Card>
          </div>
          <div class="col-md-12" >
            <lmsPagination
              v-if="this.pagination && this.activeDataSet.length > 0"
              :data="this.pagination"
              @goToPage="goToPage">
            </lmsPagination>
          </div>
        </div>
        <div v-else>
          <p :class="$style.noContent">There is no Assignment.</p>
        </div>
      </div>
      </div>
    </div>
    <RsgLink></RsgLink>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex';
  import HeaderCustom from '@/components/HeaderCustom';
  import InnerLoading from '@/components/InnerLoading';
  import lmsPagination from '@/components/pagination';
  import RsgLink from '@/components/RSGLink';
  import services from '@/services/content';
  import _ from 'lodash';


  import Card from '@/components/Card2';
  import Search from '@/components/Search';

  export default {
    name: 'assignments',
    data() {
      return {
        ready: false,
        data: [],
        title: '',
        contentType: '',
        searchText: '',
        activeTab: '',
        activeDataSet: [],
        tabData: ['current', 'past'],
        pagination: null,
        assignments2: null,
      };
    },
    components: {
      HeaderCustom,
      Card,
      Search,
      InnerLoading,
      lmsPagination,
      RsgLink,
    },
    computed: {
      ...mapGetters(['courseworks']),
      ...mapState(['assignments']),
    },
    async beforeMount() {
      this.setData();
    },
    methods: {
      ...mapActions(['getLmsAssignments']),
      updateView(tabParam) {
        if (!tabParam) {
          tabParam = 'current';
        }
        this.activeTab = tabParam;
        this.setTabView();
        this.setTabData();
      },
      setData() {
        const querystring = this.$route.query;
        if (querystring.page) {
          this.getData(querystring.page);
        } else {
          this.goToPage(1);
        }
      },
      goToPage(page) {
        this.$router.push({
          name: 'studentAssignments',
          params: {
          },
          query: { page },
        });
      },
      async getData(page) {
        this.ready = false;
        this.title = 'ASSIGNMENTS';
        this.activeTab = '';
        const res = await services.getAssignments(page);
        this.assignments2 = res.data.data;
        this.pagination = res.data.pagination;
        this.contentType = 'scorm';
        this.updateView();
        this.ready = true;
        if (window.location.search && window.location.search.includes('?')) {
          const querystring = window.location.search.split('?')[1];
          if (querystring.split('=')[0] === 'tab') {
            const tabName = querystring.split('=')[1];
            this.updateView(tabName);
          }
        }
      },
      setTabView() {
        //* tabData yı kullanrak tüm tabları normale çek ardından aktif olanı active çek
        this.tabData.forEach((element) => {
          this.$refs[element].classList = 'nav-link';
        });
        this.$refs[this.activeTab].classList = 'nav-link active';
      },
      setTabData() {
        //* Datayı aktif olan taba göre şekillendir (eğer dolu ise searchText de dikkate alınır)
        const now = new Date();
        if (this.activeTab === 'current') {
          this.activeDataSet = this.assignments2.filter(
            x => this.dateFromString(x.start_date).getTime() < now.getTime() &&
            this.dateFromString(x.end_date).getTime() > now.getTime() &&
            (x.assignment_name.toLocaleLowerCase('tr-TR').includes(this.searchText) ||
            x.scorm.scorm_name.toLocaleLowerCase('tr-TR').includes(this.searchText)));
          this.activeDataSet = _.orderBy(this.activeDataSet, ['end_date', 'scorm.scorm_name'], ['asc', 'asc']);
        } else if (this.activeTab === 'past') {
          this.activeDataSet = this.assignments2
          .filter(x => this.dateFromString(x.end_date).getTime() < now.getTime() &&
           (x.assignment_name.toLocaleLowerCase('tr-TR').includes(this.searchText) ||
            x.scorm.scorm_name.toLocaleLowerCase('tr-TR').includes(this.searchText)));
          this.activeDataSet.forEach((item) => {
            item.state_name = 'Expired';
          });
          this.activeDataSet = _.orderBy(this.activeDataSet, ['end_date', 'scorm.scorm_name'], ['desc', 'asc']);
        }
        // else if (this.activeTab === 'google') {
        // this.activeDataSet =
        // this.courseworks.
        // filter(x => (x.title.toLocaleLowerCase('tr-TR').includes(this.searchText)));
        // }
      },
      search(text) {
        if (!text) {
          this.searchText = '';
        } else {
          this.searchText = text.toLocaleLowerCase('tr-TR');
        }
        this.updateView(this.activeTab);
      },
      dateFromString(str) {
        const a = str.split(/[^0-9]/).map(x => parseInt(x, 10));
        return new Date(a[0], a[1] - 1 || 0, a[2] || 1, a[3] || 0, a[4] || 0, a[5] || 0, a[6] || 0);
      },
    },
    watch: {
      $route() {
        this.setData();
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  .nav-tabs {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

</style>
