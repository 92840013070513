module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#0f3d69',
    secondaryColor: '#2596be',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'riu0wcHWUp67XUAT24Gs2g7b872NMA1xGC4aFVpZayx4PX8y',
};
